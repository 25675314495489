import React from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import TrustedBy from "../../components/TrustedBy"
import FeaturedWork from "../../components/MOB_FeaturedWork"
import DevelopmentProcess from "../../components/MOB_DevelopmentProcess"
import TechStack from "../../components/MOB_TechStack"
import SideForm from "../../components/SideForm"
import "../../styles/services.scss"
import "../../styles/animate.css"

const { Title } = Typography

const Index = ({ data }) => {
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout className="services-page-container" data={data} 
    pageTitle={"Visiomate - Mobile Development Services"}
    descrip = {"Bring your mobile app ideas to life. Our expert mobile development services deliver high-quality, user-friendly apps for iOS and Android. Contact us today!"}>
      <div className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo">
          <Link to="/">
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>
        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>User Friendly Mobile Applications</Title>
              <p>
                We provide state-of-the-art mobile applications to help our
                customers achieve highly accessible and user friendly
                applications. <br /> <br />
                Our focus is to enable our customers with the latest
                technologies and trends to maximize their productivity,
                collaboration, and availability.
              </p>
              <TrustedBy showHeading={false} />
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="heading-and-detail">
        <Title>Mobile app development services</Title>
        <p>
          We provide mobile applications which can interact with the enterprise
          level applications to extend the availability of the enterprise level
          solutions to the end users.
        </p>
      </div>
      <div className="cards-wrapper">
        <Card title="Native Mobile Applications">
          <p>
            Our mobile app developers can create high-quality native apps for
            both Android and iOS systems that are tailored to your business and
            security needs.
          </p>
        </Card>
        <Card title="Hybrid Mobile Applications">
          <p>
            Cross-platform apps that can function in a variety of environments
            thanks to a unique combination of native and web app technologies.
          </p>
        </Card>
        <Card title="Progressive Web Applications">
          <p>
            With Progressive Web Apps, we deliver native-like capabilities and
            installability while reaching anyone, anywhere, on any device with a
            single codebase.
          </p>
        </Card>
        <Card title="Wearables Software">
          <p>
            We can create companion apps for a number of wearable devices,
            integrate with smart devices or proprietary peripherals.
          </p>
        </Card>
      </div>
      <div className="featured-projects">
        <FeaturedWork data={data} />
      </div>
      <DevelopmentProcess />
      <TechStack />
    </Layout>
  )
}

export default Index

export const mobileDevelopmentQuery = graphql`
  query mobileDevelopmentQuery {
    FarmManagementSystem: file(
      relativePath: { eq: "FarmManagementSystem/pf.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SNGPL: file(relativePath: { eq: "mobile/SNGPLApp-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SBP: file(relativePath: { eq: "mobile/statebankapp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BigJerry: file(relativePath: { eq: "mobile/BigJerryApp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    UmrahShuttle: file(relativePath: { eq: "mobile/umrahshuttleapp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
