import React from "react"
import { Typography, Carousel } from "antd"
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons"
import Img from "gatsby-image"
import { Link } from "gatsby"

const FeaturedWork = ({ data }) => {
  const { Title } = Typography
  const settings = {
    arrows: true,
    autoplay: true,
    dots: false,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <>
      <Title level={2}>Our Featured Works</Title>
      <div className="reviews-wrapper">
        <Carousel
          {...settings}
          nextArrow={<ArrowRightOutlined />}
          prevArrow={<ArrowLeftOutlined />}
        >

          <div className="reviews-grid">
            <div className="reviews-cover">
              <Img fluid={data.BigJerry.childImageSharp.fluid} />
            </div>
            <div className="reviews-description">
              <Title level={3}>
                Fence Installer Mobile Application for Big Jerry's Fencing{" "}
              </Title>
              <p>
              Big Jerry’s Fencing is committed to being the industry leader 
              by upholding the highest standard for customer service and always 
              offering quality products at a competitive price. This Application 
              is developed for there fence installation staff to trace there assigned 
              task and complete them. <br /> <br />
              Application provide following to the installation staff: Daily Task View for Installers,
              Complete Calendar view of allocations, Dashboard that highlights the allocated jobs, 
              Pre and Post Completion checklists, Track customer location on the Map, 
              Send SMS to the Customer to let them know about your arrival.
              </p>
              <span className="view-case-study">
                <Link  />
              </span>
            </div>
          </div>

          <div className="reviews-grid">
            {/* <img className="reviews-cover" src={SBP} alt="visiomate" /> */}
            <div className="reviews-cover">
              <Img fluid={data.UmrahShuttle.childImageSharp.fluid} />
            </div>
            <div className="reviews-description">
              <Title level={3}>
                Umrah Shuttle Application for KSA Travel Agent{" "}
              </Title>
              <p>
              This application focus on Travel agent to opt this application 
              for there organizations and streamline there rides related operations. 
              Customer version of this application provides a platform to schedule, and track rides. <br /> <br />
              Application provide following to the Travel Agents/ Driver/ Customer: Ride Booking, 
              Travel Agents to manage there routes and rides, Live Driver and Customer Tracking, 
              Messages between Drive, Customer and Travel Agent officer, 
              Notification for any update regarding scheduled ride.
              </p>
              <span className="view-case-study">
                <Link  />
              </span>
            </div>
          </div>

          <div className="reviews-grid">
            <div className="reviews-cover">
              <Img fluid={data.SNGPL.childImageSharp.fluid} />
            </div>
            <div className="reviews-description">
              <Title level={3}>
                Cusotmer Connection and tracking Mobile Application for Sui Northern Gas Pipelines
                Limited (SNGPL){" "}
              </Title>
              <p>
                A mobile application that is available for public of Pakistan to
                submit the SNGPL connection request, check billing history,
                submit complaints, check complaint history. The application has
                custom designed for user-friendly experience and backend runs on
                Django based web application with most of the limitations
                mitigated like multiple sessions, advanced UI design, etc.{" "}
                <br /> <br />
                This is a mobile application that is made to entertain a very
                large number user, anyone who have nationality of Pakistan can
                benefit from it. The purpose of the application is to provide
                ability to the people of Pakistan to avail several services from
                SNGPL conveniently.
              </p>
              <span className="view-case-study">
                <Link />
              </span>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  )
}

export default FeaturedWork
